import React from "react";

import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import { FAQOptionSkeleton } from "../../../@types/contentful";

import "./FAQ.css";

export default function FAQ({ options }: { options: FAQOptionSkeleton[] }) {
    const optionElements = options.map((option, i) => <FAQOption key={i} {...option}/>);

    return (
        <section id="faq">
            <h1>FAQ</h1>
            {optionElements}
        </section>
    );
}

function FAQOption(props: FAQOptionSkeleton) {
    const answer = documentToHtmlString(props.answer);

    return (
        <div className="faq__option" data-collapsed={true}>
            <label>{props.question}</label>
            <p dangerouslySetInnerHTML={{ __html: answer }}/>
        </div>
    );
}