import React, { useContext } from "react";
import { isMobile } from "../utils";

import "./LocaleSwitch.css";

export enum Locale {
    English = "en-US",
    Russian = "ru"
}

export const LocaleContext = React.createContext<LocaleContextProps>({
    locale: Locale.English,
    setLocale: null
});

export default function LocaleSwitch() {
    const { locale, setLocale } = useContext(LocaleContext);

    if (!setLocale) {
        return <h2>Rendering language controls...</h2>;
    }

    const handleLocaleChange = () => {
        if (locale === Locale.English) {
            setLocale(Locale.Russian);
        } else {
            setLocale(Locale.English);
        }
    }

    if (!isMobile()) {
        return (
            <div className="switch">
                <input
                    id="locale-switch"
                    type="checkbox"
                    onChange={handleLocaleChange}
                    checked={locale === Locale.Russian}
                />
                <label htmlFor="locale-switch"/>
                <span className="on">EN</span>
                <span className="off">RU</span>
            </div>
        );
    }

    return (
        <select onChange={handleLocaleChange} value={locale} id="locale-dropdown">
            <option value={Locale.English}>EN</option>
            <option value={Locale.Russian}>RU</option>
        </select>
    );
}

interface LocaleContextProps {
    locale: Locale;
    setLocale: React.Dispatch<Locale> | null
}