import React from "react";

import { DNATestSkeleton, FunctionalTestsSkeleton, GlobalSkeleton } from "../../../@types/contentful";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";

import "./DNATest.css";

export default function DNATests(props: Pick<FunctionalTestsSkeleton, "dnaTests" | "dnaTestsTitle"> & Pick<GlobalSkeleton, "buyButton">) {
    const dnaTestElements = props.dnaTests.map((dnaTest, i) => <DNATest
        key={i}
        buyButton={props.buyButton}
        {...dnaTest.fields}
    />);

    return (
        <section id="dna-tests">
            <h1>{props.dnaTestsTitle}</h1>
            <div id="dna-tests__products">
                {dnaTestElements}
            </div>
        </section>
    );
}

function DNATest(props: DNATestSkeleton & Pick<GlobalSkeleton, "buyButton">) {
    const description = documentToHtmlString(props.description);

    return (
        <div className="dna-test">
            <img
                src={props.cover.fields.file?.url as string}
                alt={props.brandName}
                loading="lazy"
            />
            <div className="dna-test__overview">
                <h2>{props.brandName}</h2>
                <p dangerouslySetInnerHTML={{ __html: description }}/>
                <div className="purchase-row">
                    <a className="button" href={props.url} target="_blank" rel="noreferrer">Contact</a>
                    <p className="price">£{props.price}</p>
                </div>
            </div>
        </div>
    );
}