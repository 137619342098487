import React from "react";

import { useContentfulEntry } from "../hooks/useContentfulEntry";
import { CONTENTFUL_NEWSLETTER_ENTRY_ID } from "../constants";
import { NewsletterSkeleton } from "../@types/contentful";

import "./Newsletter.css";

export default function Newsletter() {
    const data = useContentfulEntry<NewsletterSkeleton>(CONTENTFUL_NEWSLETTER_ENTRY_ID);

    const [email, setEmail] = React.useState("");
    const [name, setName] = React.useState("");

    if (!data) {
        return <h2>Rendering newsletter...</h2>
    }

    const addSubscriber = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        fetch("/api/newsletter/subscribe", {
            method: "POST",
            body: JSON.stringify({ email, name }),
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then(async response => {
                const json = await response.json();
                alert(json.message);
            })
            .catch(error => {
                console.error("Error subscribing to newsletter", error);
                alert("There was an error subscribing you to our newsletter. Please try again later.");
            });
    }

    return (
        <footer className="newsletter">
            <form onSubmit={addSubscriber}>
                <header>
                    <h2>{data.title}</h2>
                    <p>{data.subtitle}</p>
                </header>
                <fieldset>
                    <p>
                        <label htmlFor="name">{data.nameInputLabel}</label>
                        <input
                            id="name"
                            placeholder={data.nameInputPlaceholder}
                            value={name}
                            onChange={e => setName(e.target.value)}
                        />
                    </p>
                    <p>
                        <label htmlFor="email">{data.emailInputLabel}</label>
                        <input
                            type="email"
                            id="email"
                            placeholder={data.emailInputPlaceholder}
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                        />
                    </p>
                    <button type="submit">{data.subscribeButton}</button>
                </fieldset>
            </form>
            <div className="newsletter__cover" style={{
                backgroundImage: `url(${data.cover.fields.file?.url})`
            }}/>
        </footer>
    );
}