import React from "react";

import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import { useSnapCarousel } from "react-snap-carousel";
import { ReviewSkeleton } from "../../../@types/contentful";

import "./ReviewCarousel.css";

export default function ReviewCarousel(props: CarouselProps) {
    const { scrollRef, pages, activePageIndex, prev, next, goTo, snapPointIndexes } = useSnapCarousel();

    const isFirstPage = activePageIndex <= 0;
    const isLastPage = activePageIndex === pages.length - 1;

    const pageButtons = pages.map((_, i) => {
        const isActive = activePageIndex === i;
        return <span key={i} className="carousel__pagination--button" data-active={isActive} onClick={() => goTo(i)}/>
    });

    const reviewElements = props.reviews.map((review, i) =>
        <Review key={i} isSnapPoint={snapPointIndexes.has(i)} {...review}/>
    );

    return (
        <section id="carousel">
            <div id="carousel__controls">
                <span className="carousel__controls--button" data-disabled={isFirstPage} onClick={() => prev()}>
                    &lt;
                </span>
                <ul id="carousel__scroll" ref={scrollRef}>
                    {reviewElements}
                </ul>
                <span className="carousel__controls--button" data-disabled={isLastPage} onClick={() => next()}>
                    &gt;
                </span>
            </div>
            <div id="carousel__pagination" aria-hidden>
                {pageButtons}
            </div>
        </section>
    );
}

function Review(props: ReviewSkeleton & Record<"isSnapPoint", boolean>) {
    const review = documentToHtmlString(props.review);

    return (
        <li className="carousel__item" data-snap-point={props.isSnapPoint}>
            <label>{props.name}, {props.age}</label>
            <p dangerouslySetInnerHTML={{ __html: review }}/>
        </li>
    );
}

interface CarouselProps {
    reviews: ReviewSkeleton[];
}