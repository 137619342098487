import { SocialMediaSkeleton } from "../@types/contentful";

import "./SocialMedia.css";

export default function SocialMedia(props: SocialMediaSkeleton) {
    return (
        <a href={props.url} rel="noopener noreferrer" className="social-media">
            <img
                src={props.logo.fields.file?.url as string}
                alt={props.logo.fields.title as string}
            />
            <span>{props.logo.fields.title as string}</span>
        </a>
    )
}