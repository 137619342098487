import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import { useContentfulEntry } from "../../hooks/useContentfulEntry";
import { CONTENTFUL_ABOUT_ENTRY_ID } from "../../constants";
import { AboutSkeleton } from "../../@types/contentful";

import "./About.css";

export default function About() {
    const data = useContentfulEntry<AboutSkeleton>(CONTENTFUL_ABOUT_ENTRY_ID);

    if (!data) {
        return <h2>Rendering content...</h2>;
    }

    const about = documentToHtmlString(data.about);
    const certificates = data.certificates.map(certificate =>
        <img
            className="certificate"
            key={certificate.sys.id}
            src={certificate.fields.file?.url as string}
            alt={certificate.fields.title as string}
            loading="lazy"
        />
    );

    return (
        <main data-page="about">
            <div id="about__content">
                <header className="decorative-line">
                    <h1>{data.title}</h1>
                    <p>{data.subtitle}</p>
                </header>
                <section id="about__description">
                    <p dangerouslySetInnerHTML={{ __html: about }} />
                    <div id="certificates">
                        {certificates}
                    </div>
                </section>
            </div>
            <img
                id="about__cover"
                src={data.cover.fields.file?.url as string}
                alt={data.cover.fields.title as string}
            />
        </main>
    )
}