import DNATests from "./components/DNATest";
import FAQ from "./components/FAQ";
import React from "react";

import { FunctionalTestsSkeleton, GlobalSkeleton } from "../../@types/contentful";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import { CONTENTFUL_FUNCTIONAL_TESTS_ENTRY_ID } from "../../constants";
import { useContentfulEntry } from "../../hooks/useContentfulEntry";

import "./FunctionalTests.css";

export default function FunctionalTests(props: Pick<GlobalSkeleton, "buyButton">) {
    const data = useContentfulEntry<FunctionalTestsSkeleton>(CONTENTFUL_FUNCTIONAL_TESTS_ENTRY_ID);

    if (!data) {
        return <h2>Rendering functional tests...</h2>;
    }

    const faqOptions = data.faqOptions.map(option => option.fields);
    const functionalTestsDescription = documentToHtmlString(data.functionalTestsDescription);
    const dnaTestsDescription = documentToHtmlString(data.dnaTestsDescription);

    return (
        <main data-page="functional-tests">
            <section className="details">
                <div className="details__item">
                    <h1>{data.functionalTestsTitle}</h1>
                    <p dangerouslySetInnerHTML={{ __html: functionalTestsDescription }}/>
                </div>
                <div className="details__item">
                    <h1>{data.dnaTestsTitle}</h1>
                    <p dangerouslySetInnerHTML={{ __html: dnaTestsDescription }}/>
                </div>
            </section>
            <div id="functional-testing-example">
                <label>{data.withoutTesting}</label>
                <img
                    src={data.functionalTestingExample.fields.file?.url as string}
                    alt={data.functionalTestingExample.fields.title as string}
                />
                <label>{data.withTesting}</label>
            </div>
            <FAQ options={faqOptions}/>
            <DNATests {...data} {...props}/>
        </main>
    );
}