import React, { useEffect } from "react";
import Home from "./pages/home/Home";
import NavBar from "./components/NavBar";
import Newsletter from "./components/Newsletter";
import About from "./pages/about/About";
import FunctionalTests from "./pages/functional_tests/FunctionalTests";
import Consultations from "./pages/consultations/Consultations";
import Packages from "./pages/packages/Packages";
import Approach from "./pages/approach/Approach";
import Programmes from "./pages/programmes/Programmes";

import { Locale, LocaleContext } from "./components/LocaleSwitch";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useContentfulEntry } from "./hooks/useContentfulEntry";
import { CONTENTFUL_GLOBAL_ENTRY_ID } from "./constants";
import { GlobalSkeleton } from "./@types/contentful";

import './App.css';

export default function App() {
    const [locale, setLocaleState] = React.useState(Locale.English);

    useEffect(() => {
        const storedLocale = localStorage.getItem("locale");

        if (storedLocale) {
            setLocaleState(storedLocale as Locale);
        }
    }, []);

    const setLocale = (locale: Locale) => {
        setLocaleState(locale);
        localStorage.setItem("locale", locale);
    }

    return (
        <BrowserRouter>
            <LocaleContext.Provider value={{ locale, setLocale }}>
                <PageContent/>
            </LocaleContext.Provider>
        </BrowserRouter>
    );
}

function PageContent() {
    const data = useContentfulEntry<GlobalSkeleton>(CONTENTFUL_GLOBAL_ENTRY_ID);

    if (!data) {
        return <h2>Rendering content...</h2>;
    }

    return (
        <>
            <NavBar {...data}/>
            <div className="App">
                <Routes>
                    <Route index element={<Home {...data}/>}/>
                    <Route path="/about" element={<About/>}/>
                    <Route path="/functional-tests" element={<FunctionalTests {...data}/>}/>
                    <Route path="/consultations" element={<Consultations {...data}/>}/>
                    <Route path="/approach" element={<Approach/>}/>
                    <Route path="/packages" element={<Packages {...data}/>}/>
                    <Route path="/programmes" element={<Programmes {...data}/>}/>
                </Routes>
            </div>
            <Newsletter/>
        </>
    );
}