import LocaleSwitch from "./LocaleSwitch";

import { GlobalSkeleton, NavSkeleton } from "../@types/contentful";
import { useContentfulEntry } from "../hooks/useContentfulEntry";
import { CONTENTFUL_NAV_ENTRY_ID } from "../constants";
import { useEffect, useState } from "react";
import { isMobile } from "../utils";

import "./NavBar.css";

export default function NavBar(props: Pick<GlobalSkeleton, "contactButton" | "contactEmail">) {
    const [isMobileMenuExpanded, setIsMobileMenuExpanded] = useState(false);
    const data = useContentfulEntry<NavSkeleton>(CONTENTFUL_NAV_ENTRY_ID);

    useEffect(() => {
        if (isMobileMenuExpanded) {
            const handleOutsideClick = (e: MouseEvent) => {
                const target = e.target as HTMLElement;

                if (!target.closest("#nav__mobile-menu-container") && !target.closest("#nav__mobile-menu-icon")) {
                    setIsMobileMenuExpanded(false);
                }
            }

            document.addEventListener("click", handleOutsideClick);

            return () => {
                document.removeEventListener("click", handleOutsideClick);
            }
        }
    }, [isMobileMenuExpanded]);

    if (!data) {
        return <nav>Rendering content...</nav>;
    }

    return (
        <nav>
            <ScrollProgressBar/>
            <div id="nav__content">
                <div
                    id="nav__mobile-menu-icon"
                    data-expanded={isMobileMenuExpanded}
                    onClick={setIsMobileMenuExpanded.bind(null, !isMobileMenuExpanded)}
                >
                    <span className="bar"/>
                    <span className="bar"/>
                    <span className="bar"/>
                </div>
                <div id="logo">
                    <a href="/">
                        <img src={data.logo.fields.file?.url as string} height="40" width="40" alt="logo"/>
                    </a>
                </div>
                <div id="nav__mobile-menu-container" data-expanded={isMobileMenuExpanded}>
                    <div className="content">
                        <ul id="pages">
                            <li><a href="/">{data.home}</a></li>
                            <li><a href="/about">{data.about}</a></li>
                            <li><a href="/approach">{data.approach}</a></li>
                            <li><a href="/consultations">{data.consultations}</a></li>
                            <li><a href="/packages">{data.packages}</a></li>
                            <li><a href="/programmes">{data.programmes}</a></li>
                            <li><a href="/functional-tests">{data.functionalTests}</a></li>
                        </ul>
                        {isMobile() && <LocaleSwitch/>}
                    </div>
                </div>
                <div className="components">
                    {!isMobile() && <LocaleSwitch/>}
                    <a
                        href={`mailto:${props.contactEmail}`}
                        target="_blank"
                        rel="noreferrer noopener"
                        className="button"
                    >{props.contactButton}</a>
                </div>
            </div>
        </nav>
    )
}

function ScrollProgressBar() {
    const [scrolled, setScrolled] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
            const height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
            const scrolled = (winScroll / height) * 100;

            setScrolled(scrolled);
        }

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className="scroll-progress-bar"><span style={{ width: `${scrolled}%` }}></span></div>
    );
}