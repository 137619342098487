import reportWebVitals from "./reportWebVitals";
import ReactDOM from "react-dom/client";
import * as Contentful from "contentful";
import Stripe from "stripe";
import React from "react";
import App from "./App";

import "./index.css";

const contentfulSpaceId = process.env.REACT_APP_CONTENTFUL_SPACE_ID;
const contentfulAccessToken = process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN;
const stripeSecretKey = process.env.REACT_APP_STRIPE_SECRET_KEY;

if (!contentfulSpaceId || !contentfulAccessToken) {
    throw new Error("Contentful environment variables not set");
}

if (!stripeSecretKey) {
    throw new Error("Stripe environment variables not set");
}

export const stripe = new Stripe(stripeSecretKey);

export const contentfulClient = Contentful.createClient({
    space: contentfulSpaceId,
    accessToken: contentfulAccessToken
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();