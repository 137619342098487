import Stripe from "stripe";

import { EntrySkeletonType } from "contentful";

export default function filterProductPrices<ProductEntrySkeleton extends BaseProductEntrySkeleton>(
    prices: Stripe.Price[],
    products: ProductEntrySkeleton[]
): ProductWithPrice<ProductEntrySkeleton>[] {
    return prices
        .map(price => {
            const productIdx = products.findIndex(item => {
                const productId = typeof price.product === "string" ? price.product : price.product.id;
                return item.fields.stripeProductId === productId;
            });

            if (productIdx === -1 || !price.unit_amount) {
                return null;
            }

            const product = products[productIdx];
            const formattedPrice = new Intl.NumberFormat("en-GB", {
                style: "currency",
                currency: price.currency
            }).format(price.unit_amount / 100);

            return {
                key: productIdx,
                price: formattedPrice,
                ...product.fields
            };
        })
        .filter((product): product is ProductWithPrice<ProductEntrySkeleton> => {
            return Boolean(product)
        })
        .sort((a, b) => a.key - b.key);
}

type BaseProductEntrySkeleton = EntrySkeletonType<Record<"stripeProductId", string>>
type ProductWithPrice<T extends BaseProductEntrySkeleton> = T["fields"] & ProductPriceProps

interface ProductPriceProps {
    key: number;
    price: string;
}