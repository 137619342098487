import { EntrySkeletonType, FieldsType } from "contentful";
import { useContext, useEffect, useState } from "react";
import { LocaleContext } from "../components/LocaleSwitch";
import { contentfulClient } from "../index";

export function useContentfulEntry<Fields extends FieldsType = FieldsType>(id: string): Fields | null {
    const [data, setData] = useState<Fields | null>(null);
    const { locale } = useContext(LocaleContext);

    useEffect(() => {
        contentfulClient.getEntry<EntrySkeletonType<Fields>>(id, { locale })
            .then(({ fields }) => setData(fields as Fields))
            .catch(console.error);
    }, [locale, id]);

    return data;
}