import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import { CommitmentSkeleton } from "../../../@types/contentful";

import "./Commitments.css";

export default function Commitments({ items }: { items: CommitmentSkeleton[] }) {
    return (
        <section id="commitments">
            {items.map((data, i) => <Commitment key={i} {...data} />)}
        </section>
    )
}

function Commitment(props: CommitmentSkeleton) {
    const description = documentToHtmlString(props.description);

    return (
        <div className="commitment">
            <img
                src={props.cover.fields.file?.url as string}
                alt={props.cover.fields.title as string}
                loading="lazy"
            />
            <label>{props.label}</label>
            <p dangerouslySetInnerHTML={{ __html: description }}/>
        </div>
    )
}