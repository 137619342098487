import Stripe from "stripe";
import filterProductPrices from "../../hooks/filterProductPrices";

import "./Programmes.css";

import { useContentfulEntry } from "../../hooks/useContentfulEntry";
import { CONTENTFUL_PROGRAMMES_ENTRY_ID } from "../../constants";
import { GlobalSkeleton, ProgrammeSkeleton, ProgrammesSkeleton } from "../../@types/contentful";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import { useEffect, useState } from "react";
import { stripe } from "../../index";
import { Dropdown } from "../../components/Dropdown";

export default function Programmes(props: Pick<GlobalSkeleton, "buyButton">) {
    const data = useContentfulEntry<ProgrammesSkeleton>(CONTENTFUL_PROGRAMMES_ENTRY_ID);
    const [prices, setPrices] = useState<Stripe.Price[]>([]);

    useEffect(() => {
        stripe.prices.list({
            active: true,
            expand: ["data.product"],
            type: "one_time"
        }).then(prices => {
            setPrices(prices.data);
        });

        return () => setPrices([]);
    }, []);


    if (!data || !prices.length) {
        return <h2>Rendering programmes...</h2>;
    }

    const programmes = filterProductPrices(prices, data.programmes);
    const programmeElements = programmes.map(programme => {
        return (
            <Dropdown title={programme.title}>
                <Programme {...programme} buyButton={props.buyButton} key={programme.key} price={programme.price}/>
            </Dropdown>
        )
    });

    return (
        <main data-page="programmes">
            <h1>{data.title}</h1>
            <div className="programme__list">
                {programmeElements}
            </div>
        </main>
    );
}

function Programme(props: ProgrammeProps & Pick<GlobalSkeleton, "buyButton">) {
    const description = documentToHtmlString(props.description);
    const coverImages = props.coverImages.map(image => {
        return (
            <img
                src={image.fields.file!.url as string}
                alt={image.fields.title as string}
                className="programme__cover-image"
            />
        );
    });

    return (
        <div className="programme" key={props.key}>
            <div className="programme__cover-images">
                {coverImages}
            </div>
            <h2>{props.title}</h2>
            <p dangerouslySetInnerHTML={{ __html: description }}></p>
            <div className="purchase-row">
                <a
                    className="button"
                    rel="noopener noreferrer"
                    target="_blank"
                    href={props.stripeOneTimePaymentLink}
                >{props.buyButton}</a>
                <span className="price">{props.price}</span>
            </div>
        </div>
    );
}


interface ProgrammeProps extends ProgrammeSkeleton {
    key: number;
    price: string;
}