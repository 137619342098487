import Stripe from "stripe";
import filterProductPrices from "../../hooks/filterProductPrices";

import { GlobalSkeleton, PackageSkeleton, PackagesSkeleton } from "../../@types/contentful";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import { useContentfulEntry } from "../../hooks/useContentfulEntry";
import { CONTENTFUL_PACKAGES_ENTRY_ID } from "../../constants";
import { useEffect, useState } from "react";
import { stripe } from "../../index";

import "./Packages.css";

export default function Packages(props: Pick<GlobalSkeleton, "contactButton" | "contactEmail">) {
    const data = useContentfulEntry<PackagesSkeleton>(CONTENTFUL_PACKAGES_ENTRY_ID);
    const [prices, setPrices] = useState<Stripe.Price[]>([]);

    useEffect(() => {
        stripe.prices.list({
            active: true,
            expand: ["data.product"],
            type: "one_time"
        }).then(prices => {
            setPrices(prices.data);
        })

        return () => setPrices([]);
    }, []);

    if (!data || !prices.length) {
        return <h2>Rendering consultations...</h2>;
    }

    const packages = filterProductPrices(prices, data.packages);
    const contactLink = <a href={`mailto:${props.contactEmail}`}>{props.contactButton}</a>;
    const packageElements = packages.map(product => {
        return <Package
            payMonthlyButton={data.payMonthlyButton}
            payInFullButton={data.payInFullButton}
            {...product}
        />
    });

    return (
        <main data-page="packages">
            <section id="packages">
                {packageElements}
            </section>
            <p className="help-text">{data.helpText} {contactLink}</p>
        </main>
    );
}

function Package(props: PackageProps & Pick<PackagesSkeleton, "payInFullButton" | "payMonthlyButton">) {
    const description = documentToHtmlString(props.description);

    return (
        <div className="package">
            <div className="package__overview">
                <img
                    src={props.banner.fields.file?.url as string}
                    alt={props.banner.fields.title as string}
                    className="package__banner"
                />
                <span className="package__duration">{props.duration}</span>
            </div>
            <p className="package__description" dangerouslySetInnerHTML={{ __html: description }}></p>
            <div className="purchase-row">
                <p className="package__price">{props.price}</p>
                <div className="button-row">
                    <a
                        className="button"
                        rel="noreferrer noopener"
                        target="_blank"
                        href={props.stripeOneTimePaymentLink}
                    >{props.payInFullButton}</a>
                    <a
                        className="button"
                        rel="noreferrer noopener"
                        target="_blank"
                        href={props.stripeMonthlyPaymentLink}
                    >{props.payMonthlyButton}</a>
                </div>
            </div>
        </div>
    );
}

interface PackageProps extends PackageSkeleton {
    key: number;
    price: string;
}