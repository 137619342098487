import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import { useContentfulEntry } from "../../hooks/useContentfulEntry";
import { CONTENTFUL_APPROACH_ENTRY_ID } from "../../constants";
import { ApproachSkeleton } from "../../@types/contentful";

import "./Approach.css";

export default function Approach() {
    const data = useContentfulEntry<ApproachSkeleton>(CONTENTFUL_APPROACH_ENTRY_ID);

    if (!data) {
        return <h2>Rendering content...</h2>;
    }

    const approach = documentToHtmlString(data.approach);

    return (
        <main data-page="approach">
            <img
                src={data.banner.fields.file?.url as string}
                alt={data.banner.fields.title as string}
                className="approach__banner"
            />
            <p dangerouslySetInnerHTML={{ __html: approach }}/>
        </main>
    )
}