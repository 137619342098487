import Commitments from "./components/Commitments";
import ReviewCarousel from "./components/ReviewCarousel";
import SocialMedia from "../../components/SocialMedia";
import Hero from "./components/Hero";
import React from "react";

import { GlobalSkeleton, HomeSkeleton } from "../../@types/contentful";
import { useContentfulEntry } from "../../hooks/useContentfulEntry";
import { CONTENTFUL_HOME_ENTRY_ID } from "../../constants";

import "./Home.css";

export default function Home(props: Pick<GlobalSkeleton, "haveQuestionsButton" | "contactButton" | "contactEmail">) {
    const data = useContentfulEntry<HomeSkeleton>(CONTENTFUL_HOME_ENTRY_ID);

    if (!data) {
        return <h2>Rendering content...</h2>;
    }

    const reviews = data.reviews.map(review => review.fields);
    const commitments = data.commitments.map(commitment => commitment.fields);

    const socialMediaElements = data.socialMedia.map((socialMedia, i) =>
        <SocialMedia key={i} {...socialMedia.fields}/>
    );

    return (
        <main data-page="home">
            <Hero {...data}/>
            <ReviewCarousel reviews={reviews}/>
            <Commitments items={commitments}/>
            <div className="page-end">
                <hr/>
                <div className="action-row">
                    <a
                        href={`mailto:${props.contactEmail}`}
                        rel="noreferrer noopener"
                        target="_blank"
                        className="button secondary"
                    >{props.haveQuestionsButton}</a>
                    <a
                        href={`mailto:${props.contactEmail}`}
                        rel="noreferrer noopener"
                        target="_blank"
                        className="button"
                    >{props.contactButton}</a>
                </div>
                <div className="socials-row">
                    {socialMediaElements}
                </div>
            </div>
        </main>
    );
}