import Stripe from "stripe";
import filterProductPrices from "../../hooks/filterProductPrices";

import { ConsultationSkeleton, ConsultationsSkeleton, GlobalSkeleton } from "../../@types/contentful";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import { CONTENTFUL_CONSULTATIONS_ENTRY_ID } from "../../constants";
import { useContentfulEntry } from "../../hooks/useContentfulEntry";
import { useEffect, useState } from "react";
import { stripe } from "../../index";

import "./Consultations.css";

export default function Consultations(props: Pick<GlobalSkeleton, "buyButton" | "contactEmail" | "contactButton">) {
    const data = useContentfulEntry<ConsultationsSkeleton>(CONTENTFUL_CONSULTATIONS_ENTRY_ID);
    const [prices, setPrices] = useState<Stripe.Price[]>([]);

    useEffect(() => {
        stripe.prices.list({
            active: true,
            expand: ["data.product"],
            type: "one_time"
        }).then(prices => {
            setPrices(prices.data);
        });

        return () => setPrices([]);
    }, []);


    if (!data || !prices.length) {
        return <h2>Rendering consultations...</h2>;
    }

    const consultations = filterProductPrices(prices, data.consultations);
	const consultationElements = consultations.map(consultation => {
        return <Consultation {...consultation} buyButton={props.buyButton} key={consultation.key}/>
    });

    const description = documentToHtmlString(data.description);
    const cancellationPolicy = documentToHtmlString(data.cancellationPolicyDescription);

    return (
        <main data-page="consultations">
            <section id="consultations__hero">
                <img
                    src={data.cover.fields.file?.url as string}
                    alt={data.cover.fields.title as string}
                />
                <div className="consultations__hero--overview">
                    <h1>{data.title}</h1>
                    <p dangerouslySetInnerHTML={{ __html: description }}/>
                </div>
            </section>
            <section id="consultations__list">
                {consultationElements}
            </section>
            <section id="consultations__cancellation-policy">
                <blockquote>
                    <h3>{data.cancellationPolicyTitle}</h3>
                    <p dangerouslySetInnerHTML={{ __html: cancellationPolicy }}/>
                </blockquote>
            </section>
        </main>
    );
}

function Consultation(props: ConsultationProps & Pick<GlobalSkeleton, "buyButton">) {
    const description = documentToHtmlString(props.description);

    return (
        <div className="consultation">
            <h2>{props.title}</h2>
            <p dangerouslySetInnerHTML={{ __html: description }}/>
            <blockquote className="consultation__duration">🕑&nbsp;&nbsp;&nbsp;{props.duration}</blockquote>
            <div className="purchase-row">
                <a
                    className="button"
                    href={props.stripePaymentLink}
                    rel="noopener noreferrer"
                    target="_blank"
                >{props.buyButton}</a>
                <span className="price">{props.price}</span>
            </div>
        </div>
    );
}

interface ConsultationProps extends ConsultationSkeleton {
    key: number;
    price: string;
}