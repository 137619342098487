import { useState, ReactNode } from "react";
import "./Dropdown.css";

export function Dropdown(props: DropdownProps) {
    const [isCollapsed, setCollapsed] = useState(true);

    return (
        <div className="dropdown">
            <button
                className="dropdown__toggle"
                onClick={() => setCollapsed(!isCollapsed)}
            >
                {
                    isCollapsed
                        ? <img src={process.env.PUBLIC_URL + "/assets/arrow-down.png"} alt="Arrow pointing down"/>
                        : <img src={process.env.PUBLIC_URL + "/assets/arrow-up.png"} alt="Arrow pointing up"/>
                }
                {props.title}
            </button>
            <div
                className="dropdown__content"
                style={{ display: isCollapsed ? "none" : "block" }}
            >
                {props.children}
            </div>
        </div>
    );
}

interface DropdownProps {
    title: string;
    children: ReactNode;
}